import React from 'react';
import Carousel from './Carousel';

function App() {
  return (
    <div className='relative'>
      <div className='absolute flex flex-col items-center justify-center top-0 w-full h-full text-red-600 font-semibold pb-12 sm:pb-14 md:pb-16'>
        <div className='flex grow items-center mx-5 sm:mx-8 md:mx-16'>
          <img
            alt='logo'
            className='h-8 sm:h-12 md:h-24 z-10'
            src='/logo.svg'
          />
          {/* <h1 className='text-8xl z-10'>jih.architekti</h1> */}
        </div>
        <div className='mx-4 sm:mx-6 md:mx-12 text-xs xs:text-sm sm:text-base md:text-lg z-10'>
          <p>jiharchitekti@gmail.com</p>
          <p>atelier jh _ Adamská 345, 278 25 Deštná _ ing. arch. Zdeněk Pech _ +420 778 880 557</p>
          <p>atelier prg _ Janovského 31, 170 00 Praha 7 _ ing. arch. Matěj Šebek _ + 420 605 821 382</p>
        </div>
      </div>
      <Carousel />
    </div>
  );
}

export default App;
