import React, { useState, useEffect } from 'react';

function Carousel() {
  const [currentItem, setCurrentItem] = useState(0);
  const [images, setImages] = useState<{ alt: string; src: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    fetch('/carousel-images/img-list.csv')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((data) => {
        if (isMounted) {
          const parsedData = data.split(/\r?\n|\r/).map((line) => {
            const [file, name] = line.split(',');
            return {
              alt: name || file.split('.')[0],
              src: `/carousel-images/${file}`,
            };
          });
          setImages(parsedData);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (isMounted) {
          console.error('There was a problem with the fetch operation:', error);
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItem((currentItem) => (currentItem + 1) % images.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className='h-screen relative overflow-hidden'>
      {images.map(({ alt, src }, index) => (
        <img
          key={alt}
          className={`absolute h-full w-full object-cover transition-opacity duration-700 ease-in-out ${
            index === currentItem ? 'opacity-100' : 'opacity-0'
          }`}
          alt={alt}
          src={src}
        />
      ))}
    </div>
  );
}

export default Carousel;
